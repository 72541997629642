import magnifyApi from '@/http/magnify'

export const isFromMagnify = () => {
  //return window.$nvsConfig.isMagnifiUser
  const searchParams = new URLSearchParams(window.location.search);
  const uuid = searchParams.get('uuid');

  return !!uuid
}

export const isMagnifiNewProject = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const new_project = searchParams.get('new_project')

  return new_project === 'true'
  // return true
}

export const updateCosHostForMagnifyEnterprise = async () => {
  // Get the search param from the URL
  const searchParams = new URLSearchParams(window.location.search);
  const uuid = searchParams.get('uuid');
  if (!uuid || !isFromMagnify()) return;

  const formattedUuid = uuid.replaceAll(' ', '+')

  try {
    const resp = await magnifyApi.magnify_project_data({
      uuid: formattedUuid,
    })
    if (resp && resp.host) {
      localStorage.setItem('cosHost', resp.host)
    }
  } catch (e) {
    /* empty */
  }
}

export const updateMagnifiProjectSessionState = ({ state, projectId }: any) => {
  magnifyApi.update_magnify_project_state({
    isOpen: state,
    projectId,
  })
}
