import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  duration: number
  rulerRule: number
  stockMediaUploader: boolean
  markMaterialRecent: boolean
} = {
  duration: 0,
  rulerRule: 1,
  stockMediaUploader: false,
  markMaterialRecent:false,
}

export const clipeditorSlice = createSlice({
  name: 'clipeditor',
  initialState,
  reducers: {
    updateDuration(state, { payload }: PayloadAction<number>) {
      state.duration = payload
    },
    updateRulerRule(state, { payload }: PayloadAction<number>) {
      state.rulerRule = payload
    },
    updateStockMediaUploader(state, { payload }: PayloadAction<boolean>) {
      state.stockMediaUploader = payload
    },
    updateMarkMaterialRecent(state, { payload }: PayloadAction<boolean>) {
      state.markMaterialRecent = payload
    },
  },
})

export const clipeditorActions = { ...clipeditorSlice.actions }

export default clipeditorSlice.reducer
