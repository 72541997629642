import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ITtanslations {
  translatedLanguages: string[],
  translations: any,
  translationLoader: Boolean,
  currentSelectedLanguage: string,
  languagesToTranslate: string[],
  originalTranslation: string,
}

const initialState: ITtanslations = {
  translatedLanguages: [],
  translations: {},
  translationLoader: false,
  currentSelectedLanguage: '',
  languagesToTranslate: [],
  originalTranslation: ''
}

export const translationSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    updateTranslatedLanguages(state, { payload }: PayloadAction<string[]>) {
      state.translatedLanguages = payload
    },
    updateTranslations(state, { payload }: PayloadAction<any>) {
      state.translations = payload
    },
    updateTranslationLoader(state, { payload }: PayloadAction<Boolean>) {
      state.translationLoader = payload
    },
    updateCurrentSelectedLanguage(state, { payload }: PayloadAction<string>) {
      state.currentSelectedLanguage = payload
    },
    updateLanguagesToTranslate(state, { payload }: PayloadAction<string[]>) {
      state.languagesToTranslate = payload
    },
    updateOriginalTranslation(state, { payload }: PayloadAction<string>) {
      state.originalTranslation = payload
    },
  },
})

export const translationActions = { ...translationSlice.actions }

export default translationSlice.reducer