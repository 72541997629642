import { remoteApi } from './utils/constant'
import { get, post } from './utils/request'
import { post as postV2, getV2 } from './utils/internalRequest'

export default {
  magnify_project_data: (data: any) =>
    postV2({
      url: remoteApi().get_magnify_project_data,
      data
    }).then((resp: any) => {
      if (resp.code === -1) { // if account gets deactivated
        location.href = resp.routeBackUrl || 'https://db.magnifi.ai/studio'
      }
      return resp.data;
    }),
  update_magnify_project_initialization: (data: any) =>
    post({
      url: remoteApi().update_magnify_project_initialization,
      data
    }),
  get_magnify_streams: (data: any) => {
    return postV2({
      url: remoteApi().get_magnify_streams,
      data
    })
  },
  get_magnify_stream_details: (streamId: any) => {
    return getV2({
      url: `${remoteApi().get_magnify_streams_details}${streamId}`,
      headers: {},
    })
  },
  get_magnify_clips: ({ ...props }, streamId: any) =>
    getV2({
      url: `${remoteApi().get_magnify_clips}${streamId}`,
      headers: {
        params: { ...props },
      },
    }),
  get_magnifi_category_configuration: ({ ...props }, category: string) =>
    getV2({
      url: `${remoteApi().get_magnifi_category_configuration}${category}`,
      headers: {
        params: { ...props },
      },
    }),
  get_magnify_sports_categories: (data: any) => {
    return postV2({
      url: remoteApi().get_magnify_sports_categories,
      data
    })
  },
  get_magnify_sports_tornaments: (data: any) => {
    return postV2({
      url: remoteApi().get_magnify_sports_tornaments,
      data
    })
  },
  get_magnifi_clips_filters: ({ ...props }, streamId: any) =>
    getV2({
      url: `${remoteApi().get_magnifi_clips_filters}${streamId}`,
      headers: {
        params: { ...props },
      },
    }),
  update_magnify_project_state: (data: any) =>
    post({
      url: remoteApi().update_magnify_project_state,
      data
    }),
  get_magnify_project_state: ({ projectId }: { projectId: string }) =>
    get({
      url: `${remoteApi().get_magnify_project_state}/${projectId}`,
      completeRes: true,
      headers: {
        params: {},
      },
    }),
  update_magnify_clip: (data: any) => {
    return postV2({
      url: remoteApi().update_magnify_clip,
      data
    })
  },
  get_magnifi_client_settings: ({ ...props }, orgId: string) =>
    getV2({
      url: `${remoteApi().get_magnifi_client_settings}${orgId}`,
      headers: {
        params: { ...props },
      },
    }),
}
