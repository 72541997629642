import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { isFromMagnify } from '@/utils/magnifyEnterprise'
import { isMagnifiNewProject } from '@/utils/magnifyEnterprise'

type TabKey = 'all_videos' | 'project' | 'my'

interface IResourceState {
  thumbnailMap: { [key: string]: any }
  waveMap: { [key: string]: any }
  currentTabKey: TabKey
  rootFolderName: string
  isDragUpload: boolean
  refreshResourceList: boolean
  uploadCount: number,
  progress_array: Array<number>,
  added_clip_container: string,
  resourceSubCategory: string,
  resourceCategory: string,
  resourceFolder: string | null,
}

const initialState: IResourceState = {
  thumbnailMap: {},
  waveMap: {},
  currentTabKey: isMagnifiNewProject() ? 'all_videos' : 'project',
  rootFolderName: '',
  isDragUpload: false,
  refreshResourceList: false,
  uploadCount: 0,
  progress_array: [],
  added_clip_container: '',
  resourceSubCategory: "intro",
  resourceCategory: "",
  resourceFolder: null
}

export const resourceSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateUploadCount(state, { payload }) {
      if (payload.change == 'increase') {
        state.uploadCount += payload.value;
      } else if (payload.change == 'decrease') {
        state.uploadCount -= payload.value;
      }
    },
    updateThumbnailMap(state, { payload }: PayloadAction<object>) {
      state.thumbnailMap = payload
    },
    updateWaveMap(state, { payload }: PayloadAction<object>) {
      state.waveMap = payload
    },
    updateCurrentTabKey(state, { payload }: PayloadAction<TabKey>) {
      state.currentTabKey = payload
    },
    updateSubCategory(state, { payload }: PayloadAction<string>) {
      state.resourceSubCategory = payload
    },
    updateResourceFolder(state, { payload }: PayloadAction<string>) {
      state.resourceFolder = payload
    },
    updateCategory(state, { payload }: PayloadAction<string>) {
      state.resourceCategory = payload
    },
    updateRootFolderName(state, { payload }: PayloadAction<string>) {
      state.rootFolderName = payload
    },
    updateIsDragUpload(state, { payload }: PayloadAction<boolean>) {
      state.isDragUpload = payload
    },
    refreshRourceList(state, { payload }: PayloadAction<boolean>) {
      state.refreshResourceList = payload
    },
    updateProgressList(state, { payload }) {
      state.progress_array = payload
    },
    updateAddedClipContainer(state, { payload }: PayloadAction<string>) {
      state.added_clip_container = payload;
    }
  },
})

export const resourceActions = { ...resourceSlice.actions }

export default resourceSlice.reducer
