import { getApiHost, getDigitalmanHost, getLiveApiHost } from './network'

export const meisheApi = {
  // NOTE: Upload and packaging tasks related
  webApi_sts_info2: getApiHost('sts') + 'upload/sts/info',
  webApi_job_info: getApiHost('job') + 'job/batch/info',
  webApi_job_list: getApiHost('job') + 'job/list',
  webApi_job_delete: getApiHost('job') + 'job/delete',
  webApi_job_transcode: getApiHost('job') + 'job/transcode/createBatchJobs',
  webApi_job_cancel: getApiHost('job') + 'job/cancel',

  // NOTE: user related
  webApi_user_list: getApiHost('user') + 'user/list',
  webApi_userInfo: getApiHost('user') + 'usercenter/user/info',
  webApi_login: getApiHost('user') + 'usercenter/login',
  webApi_register: getApiHost('user') + 'usercenter/register',
  webApi_checkPhone: getApiHost('user') + 'usercenter/verify_code/check',
  webApi_checkEmail: getApiHost('user') + 'usercenter/user/check/email',
  webApi_checkPassword: getApiHost('user') + 'usercenter/user/update',
  webApi_forwardToEmail:
    getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
  webApi_judgeUserExist: getApiHost('user') + 'usercenter/user/exist',
  webApi_verify_code_create:
    getApiHost('user') + 'usercenter/verify_code/create',
  webApi_verify_code_image_create:
    getApiHost('user') + 'usercenter/verify_code_image/create',
  webApi_list_service_user: getApiHost('user') + 'user/listServiceUser',

  // NOTE: resource related
  webApi_resource_create: getApiHost('resource') + 'resource/create',
  webApi_resource_update: getApiHost('resource') + 'resource/update',
  webApi_resource_list: getApiHost('resource') + 'resource/list',
  webApi_resource_presigned_list: getApiHost('resource') + 'resource/presignedList',
  webApi_resource_mark_downloaded: getApiHost('resource') + 'resource/markDownload',
  webApi_resource_info: getApiHost('resource') + 'resource/info',
  webApi_resource_tag_info: getApiHost('resource') + 'resource/tag/info',
  webApi_resource_delete: getApiHost('resource') + 'resource/delete',
  webApi_resource_batch_delete: getApiHost('resource') + 'resource/batchDelete',
  webApi_resource_append: getApiHost('resource') + 'resource/append',
  webApi_resource_reverse: getApiHost('resource') + 'resource/reverse',

  // NOTE: video synchronization
  webApi_audio_align: getApiHost('audio') + 'resource/audio/compare',
  webApi_audio_result: getApiHost('audio') + 'resource/audio/cmpresult',

  // NOTE: Film related
  webApi_video_list: getApiHost('video') + 'video/list',
  webApi_video_delete: getApiHost('video') + 'video/delete',
  webApi_video_create: getApiHost('video') + 'video/create',
  webApi_video_share: getApiHost('video') + 'video/share',
  webApi_video_share_info: getApiHost('video') + 'video/share/info',

  // NOTE: material related
  webApi_material_list: getApiHost('material') + 'material/list',
  webApi_material_update: getApiHost('material') + 'material/update',
  webApi_material_all: getApiHost('material') + 'materialcenter/material/all',
  webApi_material_categories: getApiHost('material') + 'material/categories',
  webApi_material_add: getApiHost('material') + 'material/add',
  webApi_material_catalog: getApiHost() + 'project/categories',
  webApi_material_private_update:
    getApiHost('material') + 'material/private/update',
  webApi_material_private_list:
    getApiHost('material') + 'material/private/list',
  webApi_material_private_overview_list:
    getApiHost('material') + 'material/private/overviewList',
  webApi_material_private_recent_list:
    getApiHost('material') + 'material/private/recent/list',
  webApi_material_private_recent_mark:
    getApiHost('material') + 'material/private/recent/mark',
  webApi_material_private_favourite_list:
    getApiHost('material') + 'material/private/favourite/list',
  webApi_material_private_favourite_mark:
    getApiHost('material') + 'material/private/favourite/mark',
  webApi_material_listPrivate:
    getApiHost('material') + 'materialcenter/material/listPrivate',
  webApi_material_listAuthed:
    getApiHost('material') + 'materialcenter/material/listAuthed',
  webApi_material_listAll:
    getApiHost('material') + 'materialcenter/material/listAll',
  webApi_material_listTypeAndCategory:
    getApiHost('material') + 'material/listTypeAndCategory',
  webApi_material_download:
    getApiHost('material') + 'materialcenter/material/download',
  webApi_material_arrearage:
    getApiHost('material') + 'materialcenter/material/arrearageMaterial',
  webApi_material_submitMaterial:
    getApiHost('material') + 'materialcenter/material/submitMaterial',

  // NOTE: project related
  webApi_project_list: getApiHost('project') + 'project/list',
  webApi_project_info: getApiHost('project') + 'project/info',
  webApi_project_create: getApiHost('project') + 'project/create',
  webApi_project_create_empty: getApiHost('project') + 'project/create/empty',
  webApi_project_update: getApiHost('project') + 'project/update',
  webApi_project_delete: getApiHost('project') + 'project/delete',
  webApi_project_duplicate: getApiHost('project') + 'project/duplicate',
  webApi_project_compile: getApiHost('project') + 'project/compile',
  webApi_project_history_list: getApiHost('project') + 'project/history/list',
  webApi_project_history_save: getApiHost('project') + 'project/history/save',
  webApi_project_history_update:
    getApiHost('project') + 'project/history/update',
  webApi_project_clone: getApiHost('project') + 'project/clone',
  webApi_project_version_list: getApiHost('project') + 'version/list',
  webApi_project_splitdemo_list:
    getApiHost('project') + 'splitVideo/demo/public/list',
  webApi_project_create_template: getApiHost('project') + 'project/createTemplate',

  // Three-terminal intercommunication
  three_terminal_project_create:
    getApiHost('project') + 'threeTerminalProject/create',
  three_terminal_project_update:
    getApiHost('project') + 'threeTerminalProject/update',

  // NOTE: Live related
  webApi_project_reset: getLiveApiHost() + 'm3u8Transform/resetMeiShe',
  webApi_live_project_compile: getLiveApiHost() + 'project/compile',
  webApi_live_compile: getLiveApiHost() + 'project/compile',
  webApi_live_job_info: getLiveApiHost() + 'job/info',
  webApi_live_job_delete: getLiveApiHost() + 'cancelCompilingJob',
  webApi_live_getLiveResourceByOriginUrl:
    getLiveApiHost() + 'm3u8Transform/getLiveResourceByOriginUrl',
  webApi_live_refreshAuthKey:
    getLiveApiHost() + 'm3u8Transform/refreshM3u8UrlAuthKey',
  webApi_live_stopLiveResource:
    getLiveApiHost() + 'm3u8Transform/stopLiveResource',
  webApi_live_audio_to_text_start:
    getLiveApiHost() + 'm3u8Transform/startConnecting',
  webApi_live_audio_to_text_end:
    getLiveApiHost() + 'm3u8Transform/endConnecting',

  // Language to subtitle iFLYTEK voice transcription
  webApi_recognition_create: getApiHost('recognition') + 'recognition/create',
  webApi_recognition_info: getApiHost('recognition') + 'recognition/info',

  // smart label
  tag_all: getApiHost('tag') + 'tag/all',
  tag_fuzzySearch: getApiHost('tag') + 'tag/fuzzySearch',
  tag_create: getApiHost('tag') + 'tag/create',

  // digital human
  digitalman_list: getDigitalmanHost() + 'metaman/getDigitalAssets',
  digitalman_create: getDigitalmanHost() + 'metaman/createDigitalHumanVideos',
  digitalman_delete:
    getDigitalmanHost() + 'metaman/deleteDigitalAssetByResourceTitle',
  digitalman_job: getDigitalmanHost() + 'metaman/getDigitalHumanVideosJob',
  digitalman_info: getDigitalmanHost() + 'metaman/getDigitalAssetsDetail',
  digitalman_styles: getDigitalmanHost() + 'metaman/getStyles',
  digitalman_upload_picture:
    getDigitalmanHost() + 'metaman/uploadStylizedPictureModelings',
  digitalman_picture_job_list:
    getDigitalmanHost() + 'metaman/getStylizedPictureModelings',
  digitalman_picture_job_info:
    getDigitalmanHost() + 'metaman/getStylizedPictureModelingsDetail',
  digitalman_create_snapshot_job:
    getDigitalmanHost() + 'metaman/createModelSnapshotJob',
  digitalman_snapshot_job_info:
    getDigitalmanHost() + 'metaman/getModelSnapshotJob',

  // subscription
  subscription_details: getApiHost('subscription') + 'subscription-details',
  payment_plans: getApiHost('subscription') + 'plans',
  transaction_details: getApiHost('subscription') + 'transactions',
  apply_promocode: getApiHost('subscription') + 'apply-promocode',
  create_setup_intent: getApiHost('subscription') + 'stripe/create-setup-intent',
  confirm_payment: getApiHost('subscription') + 'stripe/confirm-payment',
  create_payment_intent: getApiHost('subscription') + 'stripe/create-payment-intent',
  confirm_payment_intent: getApiHost('subscription') + 'stripe/confirm-payment-intent',
  change_plan: getApiHost('subscription') + 'stripe/change-plan',
  payment_methods: getApiHost('subscription') + 'payment-methods',
  cancel_subscription: getApiHost('subscription') + 'cancel-subscription',
  create_resubscription_order: getApiHost('subscription') + 'stripe/create-resubscribe-order',
  confirm_resubscription: getApiHost('subscription') + 'stripe/confirm-resubscribe-payment',
  authorize_payment_method: getApiHost('subscription') + 'stripe/authorize-payment-method',
  confirm_auth_payment: getApiHost('subscription') + 'stripe/confirm-auth-payment',
  notify_downgraded: getApiHost('subscription') + 'notify/downgraded',
  lifetime_subscriptions_meta: getApiHost('subscription') + 'lifetime-subscriptions-meta',
  create_lifetime_order: getApiHost('subscription') + 'stripe/create-lifetime-order',
  cancel_lifetime_subscription: getApiHost('subscription') + 'cancel-lifetime-subscription',

  // users
  user_details: getApiHost('illusto2Backend') + 'v2/user',
  verify_password: getApiHost('illusto2Backend') + 'v2/user/verify-password',
  send_otp: getApiHost('illusto2Backend') + 'v2/user/send-otp',
  login_with_otp: getApiHost('illusto2Backend') + 'v2/user/login-with-otp',
  verify_otp: getApiHost('illusto2Backend') + 'v2/user/verify-otp',
  refresh_token: getApiHost('illusto2Backend') + 'v2/user/refresh-token',
  get_translations: getApiHost('subscription') + 'translation',
  get_all_translations: getApiHost('subscription') + 'translations',
  save_translations: getApiHost('subscription') + 'translation/save',

  // quotas
  storage_details: getApiHost('quotas') + 'subscription/STORAGE',
  quota_details: getApiHost('quotas') + 'subscription/',
  templates_details: getApiHost('quotas') + 'subscription/TEMPLATES',
  stock_details: getApiHost('quotas') + 'subscription/PREMIUM_STOCK',

  // templates
  get_all_templates: getApiHost('illusto2Backend') + 'v2/material/templatesv2',
  get_similar_templates: getApiHost('illusto2Backend') + 'v2/material/similar-templates',
  get_templates_by_categories: getApiHost('illusto2Backend') + 'v2/material/templates-by-categories',
  get_templates_tags: getApiHost('illusto2Backend') + 'v2/material/tags',
  user_onboarding_status: getApiHost('illusto2Backend') + 'v2/onboarding/is-onboarded',
  get_tutorial_flag: getApiHost('illusto2Backend') + 'v2/user/get-tutorial-flag',
  set_tutorial_flag: getApiHost('illusto2Backend') + 'v2/user/set-tutorial-flag',

  // notifications
  notifications_list: getApiHost('project') + 'notifications',
  notifications_mark_read: getApiHost('project') + 'notifications/mark',

  // stock media
  stock_videos_list: getApiHost('demoEditor') + 'v2/stock/search',
  add_to_fav: getApiHost('demoEditor') + 'v2/stock/add-to-fav',
  remove_from_fav: getApiHost('demoEditor') + 'v2/stock/remove-from-fav',
  all_fav_list: getApiHost('demoEditor') + 'v2/stock/all-fav',
  add_to_media: getApiHost('demoEditor') + 'v2/stock/add-to-media',
  fav_videos_list: getApiHost('demoEditor') + 'v2/stock/favorites',
  recently_used_media_list: getApiHost('demoEditor') + 'v2/stock/recently-used',

  stock_audio_list: getApiHost('demoEditor') + 'v2/stock-audio/search',
  stock_audio_add_to_fav: getApiHost('demoEditor') + 'v2/stock-audio/add-to-fav',
  stock_audio_remove_from_fav: getApiHost('demoEditor') + 'v2/stock-audio/remove-from-fav',
  stock_audio_all_fav_list: getApiHost('demoEditor') + 'v2/stock-audio/all-fav',
  stock_audio_add_to_media: getApiHost('demoEditor') + 'v2/stock-audio/add-to-media',
  stock_audio_fav_list: getApiHost('demoEditor') + 'v2/stock-audio/favorites',
  stock_audio_recently_used_media_list: getApiHost('demoEditor') + 'v2/stock-audio/recently-used',
  attach_usage_report: getApiHost('demoEditor') + 'v2/stock/attach-usage-report',

  // ai generation media
  ai_media_quota: getApiHost('quotas') + 'subscription/AI_IMAGES',
  ai_generation_media_list: getApiHost('demoEditor') + 'v2/generated-media/list',
  generate_ai_media: getApiHost('demoEditor') + 'v2/generated-media/generate',
  ai_generated_media_status: getApiHost('demoEditor') + 'v2/generated-media/status',
  add_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/add-to-media',
  remove_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/remove-image',
  remove_all_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/remove-all-images',
  log_exceptions: getApiHost('illusto2Backend') + 'v2/log-exception',

  // magnify
  get_magnify_project_data: getApiHost('magnify') + 'account/tokendata',
  update_magnify_project_initialization: getApiHost('magnify') + 'account/project/update',
  get_magnify_streams: getApiHost('magnify2') + 'streams/get',
  get_magnify_streams_details: getApiHost('magnify2') + 'streams/detail/',
  get_magnify_clips: getApiHost('magnify2') + 'get/clips/',
  get_magnifi_category_configuration: getApiHost('magnify2') + 'category-configuration/',
  get_magnify_sports_categories: getApiHost('magnify2') + 'category',
  get_magnify_sports_tornaments: getApiHost('magnify2') + 'get-tournaments',
  get_magnifi_clips_filters: getApiHost('magnify2') + 'get-clips-filter/',
  update_magnify_project_state: getApiHost('magnify') + 'account/project/status',
  get_magnify_project_state: getApiHost('magnify') + 'account/project/open',
  get_latest_project_version: getApiHost('project') + 'video/quota/list',
  update_magnify_clip: getApiHost('magnify2') + 'sony_clips/update_by_id',
  get_magnifi_client_settings: getApiHost('magnify2') + 'get-client-settings/',
}


export default {
  // NOTE: Upload and packaging tasks related
  webApi_sts_info: getApiHost('sts') + 'sts/info',
  webApi_sts_info2: getApiHost('sts') + 'upload/sts/info',
  webApi_sts_upload_local: getApiHost('sts') + 'upload/files',
  webApi_job_info: getApiHost('job') + 'job/batch/info',
  webApi_job_list: getApiHost('job') + 'job/list',
  webApi_job_list_by_user: getApiHost('job') + 'job/listByUser',
  webApi_job_delete: getApiHost('job') + 'job/delete',
  webApi_job_retry: getApiHost('job') + 'job/retry',
  webApi_job_transcode: getApiHost('job') + 'job/transcode/createBatchJobs',
  webApi_job_cancel: getApiHost('job') + 'job/cancel',

  // NOTE: user related
  webApi_user_list: getApiHost('user') + 'user/list',
  webApi_userInfo: getApiHost('user') + 'user/info',
  webApi_login: getApiHost('user') + 'login',
  webApi_register: getApiHost('user') + 'usercenter/register',
  webApi_checkPhone: getApiHost('user') + 'usercenter/verify_code/check',
  webApi_checkEmail: getApiHost('user') + 'usercenter/user/check/email',
  webApi_checkPassword: getApiHost('user') + 'usercenter/user/update',
  webApi_forwardToEmail:
    getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
  webApi_judgeUserExist: getApiHost('user') + 'usercenter/user/exist',
  webApi_verify_code_create:
    getApiHost('user') + 'usercenter/verify_code/create',
  webApi_verify_code_image_create:
    getApiHost('user') + 'usercenter/verify_code_image/create',

  user_onboarding_status: getApiHost('illusto2Backend') + 'v2/onboarding/is-onboarded',
  get_tutorial_flag: getApiHost('illusto2Backend') + 'v2/user/get-tutorial-flag',
  set_tutorial_flag: getApiHost('illusto2Backend') + 'v2/user/set-tutorial-flag',

  // NOTE: resource related
  webApi_resource_create: getApiHost('resource') + 'resource/create',
  webApi_resource_update: getApiHost('resource') + 'resource/update',
  webApi_resource_list: getApiHost('resource') + 'resource/list',
  webApi_resource_presigned_list: getApiHost('resource') + 'resource/presignedList',
  webApi_resource_mark_downloaded: getApiHost('resource') + 'resource/markDownload',
  webApi_resource_info: getApiHost('resource') + 'resource/info',
  webApi_resource_tag_info: getApiHost('resource') + 'resource/tag/info',
  webApi_resource_delete: getApiHost('resource') + 'resource/delete',
  webApi_resource_batch_delete: getApiHost('resource') + 'resource/batchDelete',
  webApi_resource_append: getApiHost('resource') + 'resource/append',
  webApi_resource_add: getApiHost('resource') + 'resource/add',
  webApi_resource_updtitle: getApiHost('resource') + 'resource/updtitle',
  webApi_resource_reverse: getApiHost('resource') + 'resource/reverse',
  webApi_resource_move: getApiHost('resource') + 'resource/moveToFolder',

  // NOTE: video synchronization
  webApi_audio_align: getApiHost('audio') + 'resource/audio/compare',
  webApi_audio_result: getApiHost('audio') + 'resource/audio/cmpresult',

  // NOTE: Film related
  webApi_video_list: getApiHost('video') + 'video/list',
  webApi_video_delete: getApiHost('video') + 'video/delete',
  webApi_video_create: getApiHost('video') + 'video/create',
  webApi_video_share: getApiHost('video') + 'video/share',
  webApi_video_share_info: getApiHost('video') + 'video/share/info',

  // NOTE: material related
  webApi_material_list: getApiHost('material') + 'materialNew/list',
  webApi_material_info: getApiHost('material') + 'materialNew/info',
  webApi_material_all: getApiHost('material') + 'materialNew/all',
  webApi_material_categories: getApiHost('material') + 'materialNew/categories',
  webApi_material_overviewList:
    getApiHost('material') + 'materialNew/overviewList',
  webApi_material_private_recent_list:
    getApiHost('material') + 'materialNew/recent/list',
  webApi_material_private_recent_mark:
    getApiHost('material') + 'materialNew/recent/mark',
  webApi_material_private_favourite_list:
    getApiHost('material') + 'materialNew/favourite/list',
  webApi_material_private_favourite_mark:
    getApiHost('material') + 'materialNew/favourite/mark',
  webApi_material_categories_multi:
    getApiHost('material') + 'materialNew/back/categories',
  webApi_material_add: getApiHost('material') + 'materialNew/user/add',
  webApi_material_catalog: getApiHost('resource') + 'project/categories',
  webApi_material_template_delete:
    getApiHost('material') + 'materialNew/delete',
  webApi_material_private_update: getApiHost('material') + 'material/update',
  webApi_material_private_list: getApiHost('material') + 'material/list',
  webApi_material_private_overview_list:
    getApiHost('material') + 'materialNew/overviewList',
  webApi_material_listPrivate:
    getApiHost('material') + 'materialcenter/material/listPrivate',
  webApi_material_listAuthed:
    getApiHost('material') + 'materialcenter/material/listAuthed',
  webApi_material_listAll:
    getApiHost('material') + 'materialcenter/material/listAll',
  webApi_material_listTypeAndCategory:
    getApiHost('material') + 'material/listTypeAndCategory',
  webApi_material_arrearage:
    getApiHost('material') + 'materialcenter/material/arrearageMaterial',
  webApi_material_submitMaterial:
    getApiHost('material') + 'materialcenter/material/submitMaterial',
  // @note api list project del update
  // NOTE: project related
  webApi_project_list: getApiHost('project') + 'project/list',
  webApi_project_info: getApiHost('project') + 'project/info',
  webApi_project_create: getApiHost('project') + 'project/create',
  webApi_project_create_empty: getApiHost('project') + 'project/create/empty',
  webApi_project_update: getApiHost('project') + 'project/update',
  webApi_project_delete: getApiHost('project') + 'project/delete',
  webApi_project_duplicate: getApiHost('project') + 'project/duplicate',
  webApi_project_compile: getApiHost('project') + 'project/compile',
  webApi_project_history_list: getApiHost('project') + 'project/history/list',
  webApi_project_history_save: getApiHost('project') + 'project/history/save',
  webApi_project_history_update:
    getApiHost('project') + 'project/history/update',
  webApi_project_clone: getApiHost('project') + 'project/clone',
  webApi_project_version_list: getApiHost('project') + 'version/list',
  webApi_project_create_template: getApiHost('project') + 'project/createTemplate',

  // Three-terminal intercommunication
  three_terminal_project_create:
    getApiHost('project') + 'threeTerminalProject/create',
  three_terminal_project_update:
    getApiHost('project') + 'threeTerminalProject/update',

  // Language to subtitle iFLYTEK voice transcription
  webApi_recognition_create: getApiHost('recognition') + 'recognition/create',
  webApi_recognition_info: getApiHost('recognition') + 'recognition/info',

  // smart label
  tag_all: getApiHost('tag') + 'tag/all',
  tag_fuzzySearch: getApiHost('tag') + 'tag/fuzzySearch',
  tag_create: getApiHost('tag') + 'tag/create',

  // subscription
  subscription_details: getApiHost('subscription') + 'subscription-details',
  payment_plans: getApiHost('subscription') + 'plans',
  transaction_details: getApiHost('subscription') + 'transactions',
  apply_promocode: getApiHost('subscription') + 'apply-promocode',
  create_setup_intent: getApiHost('subscription') + 'stripe/create-setup-intent',
  confirm_payment: getApiHost('subscription') + 'stripe/confirm-payment',
  create_payment_intent: getApiHost('subscription') + 'stripe/create-payment-intent',
  confirm_payment_intent: getApiHost('subscription') + 'stripe/confirm-payment-intent',
  change_plan: getApiHost('subscription') + 'stripe/change-plan',
  payment_methods: getApiHost('subscription') + 'payment-methods',
  cancel_subscription: getApiHost('subscription') + 'cancel-subscription',
  create_resubscription_order: getApiHost('subscription') + 'stripe/create-resubscribe-order',
  confirm_resubscription: getApiHost('subscription') + 'stripe/confirm-resubscribe-payment',
  authorize_payment_method: getApiHost('subscription') + 'stripe/authorize-payment-method',
  confirm_auth_payment: getApiHost('subscription') + 'stripe/confirm-auth-payment',
  notify_downgraded: getApiHost('subscription') + 'notify/downgraded',
  lifetime_subscriptions_meta: getApiHost('subscription') + 'lifetime-subscriptions-meta',
  create_lifetime_order: getApiHost('subscription') + 'stripe/create-lifetime-order',
  cancel_lifetime_subscription: getApiHost('subscription') + 'cancel-lifetime-subscription',

  // users
  user_details: getApiHost('illusto2Backend') + 'v2/user',
  verify_password: getApiHost('illusto2Backend') + 'v2/user/verify-password',
  send_otp: getApiHost('illusto2Backend') + 'v2/user/send-otp',
  login_with_otp: getApiHost('illusto2Backend') + 'v2/user/login-with-otp',
  verify_otp: getApiHost('illusto2Backend') + 'v2/user/verify-otp',
  refresh_token: getApiHost('illusto2Backend') + 'v2/user/refresh-token',
  get_translations: getApiHost('subscription') + 'translation',
  get_all_translations: getApiHost('subscription') + 'translations',
  save_translations: getApiHost('subscription') + 'translation/save',

  // quotas
  storage_details: getApiHost('quotas') + 'subscription/STORAGE',
  quota_details: getApiHost('quotas') + 'subscription/',
  templates_details: getApiHost('quotas') + 'subscription/TEMPLATES',
  stock_details: getApiHost('quotas') + 'subscription/PREMIUM_STOCK',

  // templates
  get_all_templates: getApiHost('illusto2Backend') + 'v2/material/templatesv2',
  get_similar_templates: getApiHost('illusto2Backend') + 'v2/material/similar-templates',
  get_templates_by_categories: getApiHost('illusto2Backend') + 'v2/material/templates-by-categories',
  get_templates_tags: getApiHost('illusto2Backend') + 'v2/material/tags',

  // notifications
  notifications_list: getApiHost('project') + 'notifications',
  notifications_mark_read: getApiHost('project') + 'notifications/mark',

  // stock videos
  stock_videos_list: getApiHost('demoEditor') + 'v2/stock/search',
  add_to_fav: getApiHost('demoEditor') + 'v2/stock/add-to-fav',
  remove_from_fav: getApiHost('demoEditor') + 'v2/stock/remove-from-fav',
  all_fav_list: getApiHost('demoEditor') + 'v2/stock/all-fav',
  add_to_media: getApiHost('demoEditor') + 'v2/stock/add-to-media',
  fav_videos_list: getApiHost('demoEditor') + 'v2/stock/favorites',
  recently_used_media_list: getApiHost('demoEditor') + 'v2/stock/recently-used',

  stock_audio_list: getApiHost('demoEditor') + 'v2/stock-audio/search',
  stock_audio_add_to_fav: getApiHost('demoEditor') + 'v2/stock-audio/add-to-fav',
  stock_audio_remove_from_fav: getApiHost('demoEditor') + 'v2/stock-audio/remove-from-fav',
  stock_audio_all_fav_list: getApiHost('demoEditor') + 'v2/stock-audio/all-fav',
  stock_audio_add_to_media: getApiHost('demoEditor') + 'v2/stock-audio/add-to-media',
  stock_audio_fav_list: getApiHost('demoEditor') + 'v2/stock-audio/favorites',
  stock_audio_recently_used_media_list: getApiHost('demoEditor') + 'v2/stock-audio/recently-used',
  attach_usage_report: getApiHost('demoEditor') + 'v2/stock/attach-usage-report',

  // ai generation media
  ai_media_quota: getApiHost('quotas') + 'subscription/AI_IMAGES',
  ai_generation_media_list: getApiHost('demoEditor') + 'v2/generated-media/list',
  generate_ai_media: getApiHost('demoEditor') + 'v2/generated-media/generate',
  ai_generated_media_status: getApiHost('demoEditor') + 'v2/generated-media/status',
  add_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/add-to-media',
  remove_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/remove-image',
  remove_all_ai_generated_media: getApiHost('demoEditor') + 'v2/generated-media/remove-all-images',
  log_exceptions: getApiHost('illusto2Backend') + 'v2/user/log-exception',

  // magnify
  get_magnify_project_data: getApiHost('magnify') + 'account/tokendata',
  update_magnify_project_initialization: getApiHost('magnify') + 'account/project/update',
  get_magnify_streams: getApiHost('magnify2') + 'streams/get',
  get_magnify_streams_details: getApiHost('magnify2') + 'streams/detail/',
  get_magnify_clips: getApiHost('magnify2') + 'get/clips/',
  get_magnifi_category_configuration: getApiHost('magnify2') + 'category-configuration/',
  get_magnify_sports_categories: getApiHost('magnify2') + 'category',
  get_magnify_sports_tornaments: getApiHost('magnify2') + 'get-tournaments',
  get_magnifi_clips_filters: getApiHost('magnify2') + 'get-clips-filter/',
  update_magnify_project_state: getApiHost('magnify') + 'account/project/status',
  get_magnify_project_state: getApiHost('magnify') + 'account/project/open',
  get_latest_project_version: getApiHost('project') + 'video/quota/list',
  update_magnify_clip: getApiHost('magnify2') + 'sony_clips/update_by_id',
  get_magnifi_client_settings: getApiHost('magnify2') + 'get-client-settings/',
}
